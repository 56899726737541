/* Custom toast styles */
.Toastify__toast-theme--colored.Toastify__toast--success {
  background: rgb(37 99 235) !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background: linear-gradient(to right, #EF4444, #F87171) !important;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  background: linear-gradient(to right, #F59E0B, #FCD34D) !important;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  background: linear-gradient(to right, #4F46E5, #818CF8) !important;
}
